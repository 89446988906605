<template>
   <div class="form">
    <el-form class="content bascform" ref="form" :model="form" label-width="100px">
        <el-form-item label="驳回" prop="RefusedText">
            <span>{{adjustmentData.providerName}}&nbsp;&nbsp;</span>
            <span style="color:#FF8200">{{adjustmentData.adjustAdvice=='1'?'一次升级到一般':adjustmentData.adjustAdvice=='2'?'一次升级到合格':adjustmentData.adjustAdvice=='3'?'一般升级到合格'
            :adjustmentData.adjustAdvice=='4'?'一般降级到一次':adjustmentData.adjustAdvice=='5'?'合格降级到一般':adjustmentData.adjustAdvice=='6'?'合格降级到淘汰'
            :adjustmentData.adjustAdvice=='7'?'一次降级到淘汰':'一般降级到淘汰'}}&nbsp;&nbsp; </span>的调整
        </el-form-item>
        <el-form-item label="驳回原因" prop="RefusedText">
            <el-input type="textarea" v-model="form.RefusedText "></el-input>
        </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'RejectBtnDialog',
  props: ['adjustmentData'],
  data: function () {
    return {
      form: {
        RefusedText: ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
</style>
